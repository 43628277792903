<template>
  <div class="new-con">
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t('Contact Us') }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section class="container text-center" style="padding-top: 36px;">
      <div class="row">
        <div class="col-md-4 col-xs-12 new-con new-con1">
          <h2>Kleopatra <span>Massage & Beauty</span></h2>
          <p>{{ $t('wep') }}</p>
        </div>
        <div class="col-md-4 col-xs-12 new-con new-con1">
          <h4>{{ $t('Address') }}</h4>
          <p>
            Messestraße 1, 6850 Dornbirn
            <br />
            Panorama Haus Hotel Dornbirn
          </p>
        </div>
        <div class="col-md-4 col-xs-12 new-con new-con3">
          <h4>{{ $t('CONTACT INFO') }}:</h4>
          <p>
            <a href="tel:+436601021010" class="contact-icon"
              >{{ $t('Phone') }}: +43(0)660 10 2 10 10
              
              </a
            >
         
           
            
            <br />
            <a href="mailto:info@kleopatramassage-beauty.com" class="contact-icon"
              >{{ $t('Email') }}: info@kleopatramassage-beauty.com</a
            >
          </p>
        </div>
      </div>
    </section>
    <conactus style="background-color: white;padding-top: 20px;" />
  </div>
</template>

<script>
import conactus from './home/conactus';

export default {
  components: {
    conactus,
  },
  head: {
    title: {
      inner: 'Contact Us',
    },
    // Meta tags
    meta: [
      { name: 'application-name', content: 'Kleopatra Massage & Beauty' },
      {
        name: 'description',
        content: 'Kleopatra Massage & Beauty',
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: 'twitter:title', content: 'Kleopatra Massage & Beauty' },
      // with shorthand
      {
        n: 'twitter:description',
        c: 'Kleopatra Massage & Beauty',
      },
      // ...
      // Google+ / Schema.org
      { itemprop: 'name', content: 'Kleopatra Massage & Beauty' },
      { itemprop: 'description', content: 'Kleopatra Massage & Beauty' },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: 'og:title', content: 'Kleopatra Massage & Beauty' },
      // with shorthand
      {
        p: 'og:image',
        c: 'https://kleopatramassage-beauty.com/image/logo.png',
      },
      // ...
    ],
  },
};
</script>
<style>
.new-con h2 {
  color: #f26838;
  text-transform: uppercase;
  margin-top: 8px;
  font-size: 24px;
  font-weight: 700;
}
.new-con h2 span {
  color: #13293d;
  text-transform: uppercase;
  margin-top: 8px;
  font-size: 24px;
  font-weight: 700;
}
.new-con p a {
  color: #8b4513;
}
.new-con h4 {
  text-transform: uppercase;
  padding-bottom: 12px;
  color: #13293d;
  font-weight: 700;
}
</style>
